
<script setup>
import { defineEmits, reactive } from 'vue';
import axiosService from '@/services/axiosService';
import { message } from 'ant-design-vue';

const InfoFormData = reactive({
  nome_completo: '',
  data_de_nascimento: '',
  naturalidade: '',
  nacionalidade: '',
  estado_civil: '',
  nome_da_mae: '',
  nome_do_pai: '',
  endereco: '',
  cidade: '',
  bairro: '',
  estado: '',
  cep: '',
  email: '',
  telefone_residencial: '',
  telefone_celular: '',
  rg: '',
  orgao_emissor: '',
  data_de_expedicao: '',
  cpf: '',
  pis: '',
  ctps: '',
  serie: '',
  estadoctps: '',
  tituloEleitor: '',
  zona: '',
  secao: '',
  uf: '',
  pne: false,
  pne_cid: '',
  ex_funcionario: false,
  parente_funcionario: false,
  dadosBancarios: {
    tipo: '',
    agencia: '',
    conta: '',
    digito: '',
    pix: '',
  },
  escolaridade: '',
  curso_de_formacao: '',
  ultima_empresa: '',
  ultimo_cargo: '',
  principais_funcoes: '',
  periodo: '',
  motivo_de_saida: '',
  tamanhosUniforme: {
    altura: '',
    blusa: '',
    calca: '',
    bota: '',
  },
  dependentes: [
    {
      nome: '',
      data_de_nascimento: '',
      parenetesco: '',
      dependenteIR: '',
    },
    {
      nome: '',
      data_de_nascimento: '',
      parenetesco: '',
      dependenteIR: '',
    },
    {
      nome: '',
      data_de_nascimento: '',
      parenetesco: '',
      dependenteIR: false,
    },
  ],
  referencias: [
    {
      nome: '',
      empresa: '',
      telefone: ''
    },{
      nome: '',
      empresa: '',
      telefone: ''
    },{
      nome: '',
      empresa: '',
      telefone: ''
    }
  ]
})

const emit = defineEmits(['handleSender'])

const formatterNumberTelephone = (value) => {
  const digits = value.replace(/\D/g, '');
  let formatted = '';
  if (digits.length > 0) {
      formatted += '(' + digits.substring(0, 2);
  }
  if (digits.length > 2) {
      formatted += ') ' + digits.substring(2, 7);
  }
  if (digits.length > 7) {
      formatted += '-' + digits.substring(7, 11);
  }
  return formatted;
}

const parserNumberTelephone = (value) => {
  return value.replace(/\D/g, '');
}

const handleSubmit = async () => {
  try {
    await axiosService.post('/mail/info', InfoFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
    emit('handleSender', InfoFormData);
  } catch (error) {
    message.warning('Algo deu errado, contate o suporte');
  }
};


</script>

<template>
  <div class="flex flex-col justify-center items-start w-full">
    <a-form
    ref="form"
    name="infoPersonal"
    class="w-full"
    layout="vertical"
    labelAlign="left"
    :model="InfoFormData"
    @submit.prevent="handleSubmit"
  >
    <a-form-item :rules="[{ required: true, message: '*' }]" label="Nome completo" class="w-full">
      <a-input v-model:value="InfoFormData.nome_completo" />
    </a-form-item>
    <div class="flex justify-around gap-5">
      <a-form-item :rules="[{ required: true, message: '*' }]" label="Data de nascimento" class="w-2/5">
        <a-config-provider :locale="locale">
          <input type="date" name="data_de_nascimento" v-model="InfoFormData.data_de_nascimento" class="ant-input css-dev-only-do-not-override-19iuou">
        </a-config-provider>
      </a-form-item>
      <a-form-item :rules="[{ required: true, message: '*' }]" label="Naturalidade" class="w-3/5">
        <a-input v-model:value="InfoFormData.naturalidade"/>
      </a-form-item>
    </div>
    <a-form-item :rules="[{ required: true, message: '*' }]" label="Nacionalidade" class="w-full">
      <a-input v-model:value="InfoFormData.nacionalidade" />
    </a-form-item>
    <a-form-item :rules="[{ required: true, message: '*' }]" label="Estado civil" class="w-full">
      <a-input v-model:value="InfoFormData.estado_civil" />
    </a-form-item>
    <a-form-item :rules="[{ required: true, message: '*' }]" label="Nome da mãe (completo*)" class="w-full">
      <a-input v-model:value="InfoFormData.nome_da_mae" />
    </a-form-item>
    <a-form-item label="Nome do pai" class="w-full">
      <a-input v-model:value="InfoFormData.nome_do_pai" />
    </a-form-item>
    <div class="flex justify-around gap-5">
      <a-form-item :rules="[{ required: true, message: '*' }]" label="Endereço" class="w-2/3">
        <a-input v-model:value="InfoFormData.endereço" />
      </a-form-item>
      <a-form-item :rules="[{ required: true, message: '*' }]" label="Bairro" class="w-1/3">
        <a-input v-model:value="InfoFormData.bairro" />
      </a-form-item>
    </div>
    <div class="flex justify-between gap-5">
      <a-form-item :rules="[{ required: true, message: '*' }]" label="Cidade" class="w-1/3">
        <a-input v-model:value="InfoFormData.cidade" />
      </a-form-item>
      <a-form-item :rules="[{ required: true, message: '*' }]" label="Estado" class="w-1/3">
        <a-input v-model:value="InfoFormData.estado" />
      </a-form-item>
      <a-form-item :rules="[{ required: true, message: '*' }]" label="CEP" >
        <a-input :controls="false" v-model:value:value="InfoFormData.cep" class="w-full"/>
      </a-form-item>
    </div>
    <div class="flex justify-between gap-5">
      <a-form-item label="Telefone Fixo" class="w-full">
        <a-input-number :controls="false" :formatter="formatterNumberTelephone" :parser="parserNumberTelephone" v-model:value="InfoFormData.telefone_residencial" class="w-full" />
      </a-form-item>
      <a-form-item :rules="[{ required: true, message: '*' }]" label="Telefone Celular" class="w-full">
        <a-input-number :controls="false" :formatter="formatterNumberTelephone" :parser="parserNumberTelephone" v-model:value="InfoFormData.telefone_celular" class="w-full"/>
      </a-form-item>
    </div>
    <a-form-item :rules="[{ required: true, message: '*' }]" label="Email" class="w-full">
      <a-input v-model:value="InfoFormData.email" />
    </a-form-item>
    <div class="flex justify-between gap-5">
      <a-form-item :rules="[{ required: true, message: '*' }]" label="RG" class="w-full">
        <a-input :controls="false"  v-model:value="InfoFormData.rg" class="w-full"/>
      </a-form-item>
      <a-form-item :rules="[{ required: true, message: '*' }]" label="Órgão emissor" class="w-full">
        <a-input v-model:value="InfoFormData.orgao_emissor" />
      </a-form-item>
      <a-form-item :rules="[{ required: true, message: '*' }]" label="Data de Expedição" class="w-full">
        <input type="date" name="data_de_expedicao" v-model="InfoFormData.data_de_expedicao" class="ant-input css-dev-only-do-not-override-19iuou">
      </a-form-item>
    </div>
    <div class="flex justify-between gap-5">
      <a-form-item :rules="[{ required: true, message: '*' }]" label="CPF/CIC" class="w-full">
        <a-input :controls="false" v-model:value="InfoFormData.cpf" class="w-full"/>
      </a-form-item>
      <a-form-item :rules="[{ required: true, message: '*' }]" label="PIS/PASEP" class="w-full">
        <a-input :controls="false" v-model:value="InfoFormData.pis" class="w-full"/>
      </a-form-item>
    </div>
    <div class="flex justify-between gap-5">
      <a-form-item :rules="[{ required: true, message: '*' }]" label="CTPS" class="w-full">
        <a-input v-model:value="InfoFormData.ctps" class="w-full"/>
      </a-form-item>
      <a-form-item :rules="[{ required: true, message: '*' }]" label="Série" class="w-full">
        <a-input v-model:value="InfoFormData.serie" />
      </a-form-item>
      <a-form-item :rules="[{ required: true, message: '*' }]" label="Estado" class="w-full">
        <a-input v-model:value="InfoFormData.estadoctps" class="w-full"/>
      </a-form-item>
    </div>
    <div>
      <div class="flex justify-between gap-5">
        <a-form-item :rules="[{ required: true, message: '*' }]" label="Titulo de eleitor" class="w-full">
          <a-input v-model:value="InfoFormData.tituloEleitor" class="w-full"/>
        </a-form-item>
        <a-form-item :rules="[{ required: true, message: '*' }]" label="Zona" class="w-full">
          <a-input v-model:value="InfoFormData.zona" />
        </a-form-item>
        <a-form-item :rules="[{ required: true, message: '*' }]" label="Seção" class="w-full">
          <a-input v-model:value="InfoFormData.secao" class="w-full"/>
        </a-form-item>
        <a-form-item :rules="[{ required: true, message: '*' }]" label="UF" class="w-full">
          <a-input v-model:value="InfoFormData.uf" class="w-full"/>
        </a-form-item>
      </div>
    </div>
    <div class="flex justify-between gap-5">
      <a-form-item label="Possui alguma deficiência?" class="flex items-center justify-center w-full">
        <a-radio-group  v-model:value="InfoFormData.pne" button-style="solid" class="flex items-center justify-center">
          <a-radio-button class="border-2" value=true >Sim</a-radio-button>
          <a-radio-button class="border-2" value=false >Não</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="Se sim, qual o tipo/CID" class="w-full">
        <a-input v-model:value="InfoFormData.pne_cid"/>
      </a-form-item>
    </div>
    <div class="flex justify-between gap-5">
      <a-form-item label="Você já trabalhou na Age Fibra?" class="w-full flex items-center justify-center">
        <a-radio-group  v-model:value="InfoFormData.ex_funcionario" button-style="solid" class="flex items-center justify-center">
          <a-radio-button class="border-2" value=true >Sim</a-radio-button>
          <a-radio-button class="border-2" value=false >Não</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="Possui parente ou amigo que trabalha ou está fazendo processo seletivo na Age Fibra?" class="w-full flex items-center justify-center">
        <a-radio-group  v-model:value="InfoFormData.parente_funcionario" button-style="solid" class="flex items-center justify-center">
          <a-radio-button class="border-2" value=true >Sim</a-radio-button>
          <a-radio-button class="border-2" value=false >Não</a-radio-button>
        </a-radio-group>
      </a-form-item>
    </div>
    <a-form-item label="Escolaridade" class="w-full">
      <a-input v-model:value="InfoFormData.escolaridade" class="w-full"/>
    </a-form-item>
    <a-form-item label="Curso de Formação" class="w-full">
      <a-input v-model:value="InfoFormData.curso_de_formacao" class="w-full"/>
    </a-form-item>
    <a-form-item label="Última empresa trabalhada" class="w-full">
      <a-input v-model:value="InfoFormData.ultima_empresa" class="w-full"/>
    </a-form-item>
    <a-form-item label="Cargo ocupado" class="w-full">
      <a-input v-model:value="InfoFormData.ultimo_cargo" class="w-full"/>
    </a-form-item>
    <div class="flex justify-between gap-5">
      <a-form-item label="Período" class="w-full">
        <a-input v-model:value="InfoFormData.periodo" class="w-full"/>
      </a-form-item>
      <a-form-item label="Motivo da saída" class="w-full">
        <a-input v-model:value="InfoFormData.motivo_de_saida" class="w-full"/>
      </a-form-item>
    </div>
    <a-form-item label="Dados bancários">
      <div class="flex justify-between gap-5 max-[500px]:flex-col ">
        <a-form-item label="Tipo">
          <a-input v-model="InfoFormData.dadosBancarios.tipo" class="w-full"/>
        </a-form-item>
        <a-form-item label="Agência">
          <a-input v-model="InfoFormData.dadosBancarios.agencia" class="w-full"/>
        </a-form-item>
        <a-form-item label="Conta">
          <a-input v-model="InfoFormData.dadosBancarios.conta" class="w-full"/>
        </a-form-item>
        <a-form-item label="Digito">
          <a-input v-model="InfoFormData.dadosBancarios.digito" class="w-full"/>
        </a-form-item>
        <a-form-item label="Chave Pix">
          <a-input v-model="InfoFormData.dadosBancarios.pix" class="w-full"/>
        </a-form-item>
      </div>
    </a-form-item>
    <a-form-item label="Dependentes">
      <div class="flex flex-wrap gap-4" v-for="(dependente, index) in InfoFormData.dependentes" :key="index">
        <a-form-item label="Nome" class="w-full max-[500px]:w-2/5">
          <a-input v-model:value="InfoFormData.dependentes[index].nome" class="w-full"/>
        </a-form-item>
        <a-form-item label="Data de nascimento" class="w-full max-[500px]:w-2/5">
          <input type="date" name="data_de_nascimento" v-model="InfoFormData.dependentes[index].data_de_nascimento" class="ant-input css-dev-only-do-not-override-19iuou">
        </a-form-item>
        <a-form-item label="Parentesco" class="w-full max-[500px]:w-2/5">
          <a-input v-model:value="InfoFormData.dependentes[index].parenetesco" class="w-full"/>
        </a-form-item>
        <a-form-item label="É seu dependente no IR?" class="w-full max-[500px]:w-2/5">
          <a-radio-group  v-model:value="InfoFormData.dependentes[index].dependenteIR" button-style="solid" class="flex items-center justify-center">
            <a-radio-button class="border-2" value=true >Sim</a-radio-button>
            <a-radio-button class="border-2" value=false >Não</a-radio-button>
          </a-radio-group>
        </a-form-item>
      </div>
    </a-form-item>
    <a-form-item label="Referências">
      <div class="flex justify-between gap-5" v-for="(referencia, index) in InfoFormData.referencias" :key="index">
        <a-form-item label="Nome" class="w-full">
          <a-input v-model:value="InfoFormData.referencias[index].nome" class="w-full"/>
        </a-form-item>
        <a-form-item label="Empresa" class="w-full">
          <a-input v-model:value="InfoFormData.referencias[index].empresa" class="w-full"/>
        </a-form-item>
        <a-form-item label="Telefone" class="w-full">
          <a-input-number :controls="false" :formatter="formatterNumberTelephone" :parser="parserNumberTelephone" v-model:value="InfoFormData.referencias[index].telefone" class="w-full" />
        </a-form-item>
        </div>
    </a-form-item>
      <div class="w-full flex justify-end">
        <a-button key="submit" type="primary" @click="handleSubmit">Enviar</a-button>
      </div>
  </a-form>
  </div>
</template>

<style lang="scss" scoped>

.ant-radio-button-wrapper {
  margin-right: 16px;
  border-radius: 8px;
  text-align: center;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

</style>