<template>
  <div class="bg-[#ebecef] h-screen w-screen flex justify-center items-center bg-opacity-80 z-100">
    <div class="bg-white rounded-3xl h-4/5 w-4/5 flex flex-row">
      <div class="h-full w-3/5 flex justify-center items-center">
        <img :src="mulher" alt="mulher" class="h-5/6">
      </div>
      <form class="h-full w-3/6 flex flex-col justify-evenly px-20 mr-10" @submit.prevent="handleSubmit">
        <img :src="logo" alt="logo" class="h-24 max-[1600px]:h-16">
        <div class="flex flex-col items-start gap-2">
          <h1 class="text-3xl font-bold font-poppins max-[1600px]:text-lg">Vamos Começar</h1>
          <h3 class="text-lg w-full text-start font-semibold font-poppins max-[1600px]:text-xs">Realize seu login utilizando seu <span class="text-age-orange">usuário Age!</span></h3>
        </div>
        <div class="flex flex-col h-1/6">
          <label class="font-bold mb-4 self-start text-xl max-[1600px]:text-lg">Identificação</label>
          <div class="flex items-center px-3 w-full">
            <img :src="user" alt="Icon" class="mr-16 h-10 max-[1600px]:h-7">
            <input 
              type="text" 
              placeholder="Digite seu usuário" 
              class="outline-none w-full placeholder-gray-400 max-[1600px]:text-xs"
              required
              v-model="formData.user"
            >
          </div>
          <span class="bg-gray-700 rounded-full h-[1px] w-full mt-5"></span>
        </div>
        <div class="flex flex-col h-1/6" >
          <label class="font-bold mb-4 self-start text-xl max-[1600px]:text-lg">Senha</label>
          <div class="flex items-center px-3 w-full">
            <img :src="password" alt="Icon" class="mr-16 h-8 max-[1600px]:h-6">
            <input 
              :type="passwordVisible ? 'text' : 'password'"
              placeholder="Digite sua senha"
              required
              class="outline-none w-full placeholder-gray-400 max-[1600px]:text-xs"
              v-model="formData.password"
            >
            <button 
              type="button" 
              @click="togglePasswordVisibility"
              class="cursor-pointer self-end"
            >
              <img :src="passwordVisible ? hideIcon : showIcon" alt="Show/Hide Icon" class="w-6">
            </button>
          </div>
          <span class="bg-gray-700 rounded-full h-[1px] w-full mt-5"></span>
        </div>
        <div>
          <button @click.prevent="handleSubmit" class="bg-[#0F297D] w-full h-16 rounded-3xl text-white text-xl max-[1600px]:text-base max-[1600px]:h-10 ">
            Log in
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import logo from '@/assets/Logomarca.svg'
import mulher from '@/assets/Mulher.svg'
import user from '@/assets/user.svg'
import password from '@/assets/password.svg'
import showIcon from '@/assets/showIcon.svg'
import hideIcon from '@/assets/hideIcon.svg'
import { ref, defineEmits  } from 'vue';

const passwordVisible = ref(false)
const formData = ref({
  user: '',
  password: ''
})

const emit = defineEmits([ 'loginAuth' ])

const handleSubmit = () => {
  return !formData.value.user & !formData.value.password ? new Error() : emit('loginAuth', formData)
}

const togglePasswordVisibility = () => {
      passwordVisible.value = !passwordVisible.value;
    }

</script>

<style lang="scss" scoped>
</style>