<template>
  <div class="fixed left-0 top-0 flex flex-col w-40 h-screen bg-white shadow-lg">
    <div class="flex items-center justify-center py-6">
      <img :src="ageLogo" alt="Logo" class="h-10" />
    </div>
    <div class="flex flex-col items-center mt-6">
      <div class="size-24 rounded-full bg-gray-300 flex items-center justify-center">
        <img :src="user" alt="Avatar" class="h-full rounded-full" />
      </div>
      <span class="mt-4 text-lg">{{ props.userName }}</span>
    </div>
    <nav class="mt-10" id="navbar">
      <span id="roles" @click="changePage('roles')" class="cursor-pointer flex items-center justify-center text-gray-600 font-semibold font-poppins text-sm rounded-full p-3 pr-5">
        <img :src="prancheta" alt="prancheta" class="h-6 px-2">
        Cargos
      </span>
    </nav>
    <div class="mt-auto mb-6 px-3">
      <span class="cursor-pointer" @click="handleExit">
        Sair
      </span>
    </div>
  </div>
</template>

<script setup>
import ageLogo from '@/assets/logoAzul.svg'
import user from '@/assets/Usuário.svg'
import prancheta from '@/assets/Prancheta.svg'
import { defineEmits, defineProps } from 'vue';

const emit = defineEmits([ 'logOutHandler', 'subjectChanger' ])
const props = defineProps([ 'userName' ])

const handleExit = () => {
  return emit('logOutHandler')
}

const changePage = (param) => {
  const nodeList = document.getElementById('navbar').children;
  nodeList.forEach(item => item.classList.remove('selected'))
  document.getElementById(param).classList.add('selected')
  return emit('subjectChanger', param)
}

</script>

<style lang="scss" scoped>
.selected {
  background-color: #dfdfdf;
}
</style>