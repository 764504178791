<template>
  <div
    @wheel="handleScroll"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    class="relative h-dvh flex flex-row justify-between max-[500px]:flex-col max-[500px]:items-center"
  >
    <div class="relative flex flex-col justify-center items-center gap-12 w-1/2 max-[500px]:2/5 max-[500px]:hidden">
      <img :src=ageLogo class="logo absolute h-1/6 max-[500px]:h-2/3">
      <p class="textBody absolute text-wrap text-[#919CA2] font-poppins items-center w-4/6 text-xl max-[1600px]:text-base">A Age teve início a partir do grupo econômico, Rialma, sucesso em implantar projetos na área de energia hidráulica, eólica, transmissão de energia, mineração e agropecuária, surgindo do desafio social de levar infraestrutura a todos. Nosso objetivo é possibilitar o acesso ao ensino, à informação, aos serviços de streaming, às consultas médicas, ao home office, ao envio de documentos de tamanho ilimitado: ao entretenimento em seu maior grau de imersão ou ao trabalho em seu maior grau de credibilidade. Queremos que o cliente Age tenha certeza que vamos atendê-lo sempre e que ele se concentre apenas em sua experiência, sem preocupações com quedas ou instabilidades.</p>
    </div>
    <div class="flex flex-col justify-evenly w-1/2 right-0 gap-8 -top-24 bottom-0 max-[500px]:w-4/5 max-[500px]:h-full max-[500px]:justify-center">
      <div class="card1 flex flex-row z-10 h-auto shadow-[0_15px_40px_-15px_rgba(0,0,0,0.3)] border-white bg-white rounded-lg">
        <img :src=missionLogo>
        <div class="flex flex-col text-start font-poppins">
          <h4 class="font-bold text-2xl max-[500px]:text-lg py-6">Missão</h4>
          <p class="text-[#919CA2] w-11/12 max-[500px]:text-xs">Gerar experiência ilimitada em conectividade a todos.</p>
        </div>
      </div>
      <div class="card2 flex flex-row z-10 h-auto shadow-[0_15px_40px_-15px_rgba(0,0,0,0.3)] border-white bg-white rounded-lg">
        <img :src=vissionLogo>
        <div class="flex flex-col text-start font-poppins">
          <h4 class="font-bold text-2xl max-[500px]:text-lg py-6">Visão</h4>
          <p class="text-[#919CA2] w-11/12 max-[500px]:text-xs">Ser referência em tecnologia, capilaridade e infraestrutura no Centro Oeste.</p>
        </div>
      </div>
      <div class="card3 flex flex-row z-10 h-auto shadow-[0_15px_40px_-15px_rgba(0,0,0,0.3)] border-white bg-white rounded-lg">
        <img :src=valorLogo>
        <div class="flex flex-col text-start font-poppins">
          <h4 class="font-bold text-2xl max-[500px]:text-lg py-6">Valores</h4>
          <p class="text-[#919CA2] w-11/12 max-[500px]:text-xs">Tecnologia, Capilaridade, Inovação, Melhoria contínua e Planejamento.</p>
        </div>
      </div>
    </div>
    <a href="#" @click.prevent="scrollToDiv('formularios')">
      <img
        :src=scroll
        class="hover:animate-bounce w-10 absolute right-8 bottom-8 z-10"
      >
    </a>
  </div>
</template>

<script setup>
import ageLogo from "@/assets/logoAge.svg"
import missionLogo from "@/assets/iconMissao.svg"
import scroll from '@/assets/Scroll.svg';
import vissionLogo from "@/assets/iconVisao.svg"
import valorLogo from "@/assets/iconValores.svg"
import router from '@/router';

const handleScroll = (event) => {
  setTimeout(() => {
    if (event.deltaY > 0) {
      router.push('/formularios')
    } else {
      router.push('/')
    }
  },500)
}

let startY = 0;

const handleTouchStart = (event) => {
  startY = event.touches[0].clientY;
}

const handleTouchMove = (event) => {
  event.preventDefault();      
  const currentY = event.touches[0].clientY;
  const deltaY = currentY - startY;

  setTimeout(() => {
    if (deltaY < 0) {
      router.push('/formularios')
    } else {
      router.push('/')
    }
  },500)
};

const scrollToDiv = (selector) => {
  router.push(`/${selector}`)
}

</script>

<style lang="scss" scoped>
.card1 {
  opacity: 0;
  animation: cardAnimation 1.5s ease-out forwards;
}

.card2 {
  opacity: 0;
  animation: cardAnimation 1.5s ease-out forwards;
  animation-delay: 1.5s;
}

.card3 {
  opacity: 0;
  animation: cardAnimation 1.5s ease-out forwards;
  animation-delay: 3s;
}

.logo {
  opacity: 0;
  animation: logoAnimation 1s ease-out forwards;
}

.textBody {
  opacity: 0;
  animation: textAnimation 1.5s forwards;
  animation-delay: 1s;
}

@media screen and (max-width: 600px) {
  .logo {
    opacity: 0;
    animation: logoAnimationMobile 1s ease-out forwards;
  }

  .textBody {
    opacity: 0;
    animation: textAnimationMobile 1.5s forwards;
    animation-delay: 1s;
  }
}


@keyframes cardAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  } 
}

@keyframes logoAnimation {
  0% {
    top: -100%;
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    top: 10%;
    opacity: 1;
  } 
}

@keyframes textAnimation {
  0% {
    bottom: -100%;
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    bottom: 30%;
    opacity: 1;
  } 
}

@keyframes logoAnimationMobile {
  0% {
    top: -100%;
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    top: 100%;
    opacity: 1;
  } 
}

@keyframes textAnimationMobile {
  0% {
    bottom: -100%;
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    bottom: 30%;
    opacity: 1;
  } 
}

</style>