<template>
  <div id="section-tracker">
      <div id="Home" class="tracker h-0.5"></div>
      <div id="Descrição" class="tracker h-0.5"></div>
      <!-- <div id="Video" class="tracker h-0.5"></div> -->
      <div id="Formulários" class="tracker h-0.5"></div>
  </div>
</template>

<script setup>
import { computed, onMounted, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute()
const currentRoute = computed(() => route.path);

const handleActive = () => {
  const trackers = document.querySelectorAll('#section-tracker .tracker')
  const currentRoute = route.name

  trackers.forEach(tracker => {
    if (tracker.id === currentRoute) {
      tracker.classList.add('active');
    } else {
      tracker.classList.remove('active');
    }
  });
}

watch(currentRoute, () =>  handleActive())
onMounted(handleActive)
</script>

<style lang="scss" scoped>
  #section-tracker {
    position: fixed;
    z-index: 999;
    top: 6.5rem;
    right: 3rem;
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: end;
}

.tracker {
    height: 0.1rem;
    width: 1rem;
    background-color: #FF8B3D;
    transition: height 0.2s ease-out;
}

@keyframes growUp {
  from {
    height: 0.1rem;
    width: 1rem;
  }
  to {
    height: 0.15rem;
    width: 2rem;
  }
}

.tracker.active {
    animation-name: growUp;
    animation-duration: 1s;
    height: 0.15rem;
    width: 2rem;
}


</style>