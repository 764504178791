<template>
  <div
    class="w-full flex justify-center z-10 p-3 bg-white fixed"
  >
    <img :src=ageLogo alt="logo">
  </div>
  <div
    @wheel="handleScroll"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    class="relative flex flex-row bg-gradient-to-r from-[#FFF8F3] to-white h-svh max-[500px]:flex-col"
  >
    <div class="relative w-1/2 max-[500px]:w-full max-[500px]:h-1/2 max-[500px]:pl-8">
      <img :src=princiaplImg class="imgAnimate absolute -mx-40 h-5/6 max-[500px]:h-4/6 max-[500px]:-mx-10">
    </div>
    <div class="textAnimate relative w-1/2 max-[500px]:w-full max-[500px]:h-2/3">
      <div class="flex flex-col font-poppins pt-5 max-[500px]:pt-1 h-3/4 max-[1600px]:pt-28">
        <div class="flex flex-1 max-[500px]:hidden"></div>
        <div class="flex flex-col items-start text-black text-7xl font-extrabold max-[500px]:text-xl max-[500px]:items-center">
            <h1>Seja bem-vindo</h1>
            <p>ao <span class="text-age-orange">Grupo Age</span></p>
        </div>
        <div class="flex flext-row text-start pt-5 text-xl text-[#919CA2] w-6/12 max-[500px]:text-center max-[500px]:text-base max-[500px]:mx-auto">
          <P><span class="font-bold">Parabéns! Você foi aprovado(a)</span> para a vaga de <span class="text-age-orange">{{ role }}</span> na empresa <span class="font-bold">Age Fibra</span>. 
            Estamos muito felizes em tê-lo(a) como parte do nosso time. Sua aprovação é um reflexo de suas habilidades e do seu potencial para contribuir com o nosso crescimento.
          </P>
        </div>
        <div class="flex flex-1 max-[500px]:hidden"></div>
        <!-- <a class="bg-age-orange rounded-full w-56 h-16 flex flex-row justify-evenly items-center max-[500px]:h-8 max-[500px]:text-base max-[500px]:mx-auto max-[500px]:mt-8" href="">
          <img :src=btnPlay>
          <P>Video Institucional</P>
        </a> -->
      </div>
    </div>
    <a href="#" @click.prevent="scrollToDiv('descricao')">
      <img
        :src=scroll
        class="hover:animate-bounce w-10 absolute right-8 bottom-8"
      >
    </a>
  </div>
</template>

<script setup>
import ageLogo from "@/assets/logoAge.svg"
import scroll from '@/assets/Scroll.svg';
// import btnPlay from '@/assets/btnPlay.svg';
import princiaplImg from '@/assets/meninaPrincipal.svg';
import router from '@/router';
import { onMounted, ref } from 'vue';
import axiosInstance from "@/services/axiosService";
import { useHashStore } from '@/store'

let startY = 0;

const store = useHashStore();

const handleScroll = (event) => {
  setTimeout(() => {
    if (event.deltaY > 0) {
      router.push('/descricao')
    }
  }, 500)
}

const handleTouchStart = (event) => {
  startY = event.touches[0].clientY;
}

const handleTouchMove = (event) => {
  event.preventDefault();      
  const currentY = event.touches[0].clientY;
  const deltaY = currentY - startY;

  setTimeout(() => {
    if (deltaY < 0) {
      router.push('/descricao')
    }
  }, 500)
};

const role = ref('');
const getRoleHash = async () => {
  const formData = new FormData();
  const sessionHash = sessionStorage.getItem('roleHash')
  const url = new URLSearchParams(window.location.search)
  const hash = !url.get('hash') ? (sessionHash ? sessionHash : router.push('/error')) : url.get('hash');
  formData.append('hash', hash)
  try {
    const res = await axiosInstance.post('/hash/validate', formData)
    return !res.data.valid ? router.push('/error') : saveRole(res.data.cargo, hash);
  } catch (error) {
    console.error(error)
    router.push('/errror')
  }

}

const saveRole = (roleName, hash) => {
  role.value = roleName
  store.saveRoleHash(hash)
}

const scrollToDiv = (selector) => {
  router.push(`/${selector}`)
}

onMounted(getRoleHash);
</script>

<style lang="scss" scoped>
@media screen and (max-width: 600px) {
  .imgAnimate {
    opacity: 0;
    animation: imgAnimationPhone 2s ease-out forwards;
  }
}

@media screen and (min-width: 601px) {
  .imgAnimate {
    opacity: 0;
    animation: imgAnimation 2s ease-out forwards;
  }
}

.textAnimate {
  opacity: 0;
  animation: textAnimation 2.4s ease-out forwards;
}

@keyframes imgAnimation {
  0% {
    bottom: -100%;
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    bottom: 10%;
    opacity: 1;
  }
}

@keyframes imgAnimationPhone {
  0% {
    top: -100%;
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    top: 30%;
    opacity: 1;
  }
}

@keyframes textAnimation {
  0% {
    right: -100%;
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    right: 0%;
    opacity: 1;
  }
}

</style>