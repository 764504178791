<template>
  <div class="background">
    <LoginComponent v-if="!isAuth && page === 'login' " @loginAuth="loginAuth"></LoginComponent>
    <LoadingComponent v-if="loading && page === 'loading' "></LoadingComponent>
    <div v-if="isAuth && page === 'home' " class="w-sceen h-screen">
      <MenuComponent
        @logOutHandler="logOutHandler"
        :userName="userName"
        @subjectChanger="subjectChanger"
      ></MenuComponent>
      <div class="ml-40 flex-1 p-4 h-dvh">
        <RoleListCard class="h-full w-full" v-if="subject === 'roles' "></RoleListCard>
      </div>
    </div>
  </div>
</template>

<script setup>
import LoginComponent from '@/components/adm/LoginComponent.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import MenuComponent from '@/components/adm/MenuComponent.vue';
import RoleListCard from '@/components/adm/RoleListCard.vue';
import { onMounted, ref } from 'vue';
import { useAuthStore } from '@/store';
import { message } from 'ant-design-vue';

const isAuth = ref(false)
const loading = ref(false)
const userName = ref('')
const authStore = useAuthStore()
const page = ref('login')
const subject = ref('')

const loginAuth = async (data) => {
  loading.value = true;
  setPage('loading')

  try {
    const response = await authStore.loginService(data.value);
    if (response.error) {
      message.warning(response.error)
      loading.value = false;
      setPage('login')
      return;
    }
    userName.value = formatName(response)  
    loading.value = false;
    isAuth.value = true;
    setPage('home');
  } catch (error) {
    isAuth.value = false;
    loading.value = false;
    setPage('login')
  }
}

const subjectChanger = (param) => {
  subject.value = param
}

const setPage = (param) => {
  page.value = param
}

function formatName(input) {
  return input
    .split('.')
    .map(part => part.charAt(0).toUpperCase() + part.slice(1))
    .join(' ');
}

const logOutHandler = () => {
  isAuth.value = false;
  authStore.logoutService();
  page.value = 'login'
  subject.value = ''
}

onMounted(() => {
  const token = authStore.retriveToken()
  if (!token ) {
    return false
  }

  isAuth.value = true
  page.value = 'home'
})

</script>

<style lang="scss" scoped>

.background {
  background-image: url('../assets/Base.svg');
  background-size: cover;
  height: 100vh;
  width: 100vw;
}


</style>