import { createPinia } from 'pinia'
import { createApp } from 'vue'

import "@/main.css"
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';

const app = createApp(App);

app.use(VuePlyr, {
  plyr: {}
})
app.use(createPinia())
app.use(router)
app.use(Antd)

app.mount('#app')
