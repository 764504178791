import { defineStore } from 'pinia';
import axios from 'axios';
import { ref } from 'vue';

export const useAuthStore = defineStore('authStore', () => {
  const token = ref('');

  const formatterName = (fullName) => {
    const nameParts = fullName.trim().split(" ");
  
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];
  
    return `${firstName} ${lastName}`;
  }

  const validateUser = (user) => {
      const validNames = ["andressa.oliveira", "erika.santos", "kenya.elvino", "joao.levyski"];
      return validNames.includes(user.toLowerCase());
  }

  const loginService = async (data) => {
    if (!validateUser(data.user)) {
      return{ error: 'Usuário não autorizado'}
    }

    try {

      const response = await axios.post(
        'https://v2.ageportal.agetelecom.com.br/portal/auth/login',
        new URLSearchParams({
          user: data.user,
          password: data.password,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      const accessToken = response.data.access_token;
      token.value = accessToken;
      saveToken(accessToken);
      const name = formatterName(response.data.user.name)
      return name;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  const parseToken = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  const saveToken = (token) => {
    localStorage.setItem('token',  token)
  }

  const retriveToken = () => {
    token.value = localStorage.getItem('token')
    return token.value ? token.value : false
  }

  const logoutService = () => {
    localStorage.removeItem('token')
  }

  return { loginService, parseToken, retriveToken, logoutService }
})


export const useHashStore = defineStore('hashStore', () => {
  const roleHash = ref('');

  const saveRoleHash = (value) => {
    roleHash.value = value
    sessionStorage.setItem('roleHash', value)
  }

  return { saveRoleHash }
})