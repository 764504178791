import { createRouter, createWebHistory } from 'vue-router'
import LandingCard from '@/components/landingPage/LandingCard.vue'
import DescriptionCard from '@/components/landingPage/DescriptionCard.vue'
import VideoCard from '@/components/landingPage/VideoCard.vue'
import FormsSections from '@/components/landingPage/FormsSections.vue'
import AdmView from '@/views/AdmView.vue'
import ErrorView from '@/views/ErrorView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: LandingCard
  },
  {
    path: '/descricao',
    name: 'Descrição',
    component: DescriptionCard
  },
  {
    path: '/video',
    name: 'Video',
    component: VideoCard
  },
  {
    path: '/formularios',
    name: 'Formulários',
    component: FormsSections
  },
  {
    path: '/adm',
    name: 'Administração',
    component: AdmView,
  },
  {
    path: '/error',
    name: '404 not Found',
    component: ErrorView,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/error',
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if(savedPosition) {
      return savedPosition
    } else {
      return { top: 0, behavior: 'smooth' }
    }
  }
})

export default router
