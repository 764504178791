<template>
  <div class="h-svh w-svw flex gap-11 flex-col justify-center items-center">
    <h1 class="text-5xl font-extrabold text-age-orange">404 - Not Found</h1>
    <img :src="ageLogo" alt="logo">
  </div>
</template>

<script setup>
import ageLogo from '@/assets/Logomarca.svg'
import { onMounted } from 'vue';

onMounted(() => {
  sessionStorage.clear();
})

</script>

<style lang="scss" scoped>

</style>