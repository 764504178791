<template>
  <div>
    <a-form
      ref="form"
      name="infoPersonal"
      class="w-full"
      layout="vertical"
      labelAlign="left"
      :model="filesArr"
      @submit.prevent="handleSubmit"
    >
    <a-form-item :rules="[{ required: true, message: '*' }]" label="Nome completo" class="w-full">
      <a-input v-model:value="nome_completo" />
    </a-form-item>
    <div class="flex flex-row justify-between gap-4 w-full">
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="RG (frente e verso) Somente será aceito a Carteira de Identidade*">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'rg')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="Foto 3x4">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'foto3x4')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
    </div>
    <div class="flex flex-row justify-between gap-4 w-full">
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="CTPS">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'ctps')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="CPF/CIC">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'cpf/cic')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
    </div>
    <div class="flex flex-row justify-between gap-4 w-full">
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="CNH - obrigatório para quem será condutor de veículo em sua função">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'cnh')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="Carteira de reservista">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'carteira-de-reservista')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
    </div>
    <div class="flex flex-row justify-between gap-4 w-full">
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="PIS/PASEP">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'pis/pasep')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="Numero do SUS (CNS)">
          <a class="text-age-blue" target="_blank" href="https://cnesadm.datasus.gov.br/cnesadm/publico/usuarios/cadastro" >Site para consultar</a>
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'numero-do-sus')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
    </div>
    <div class="flex flex-row justify-between gap-4 w-full">
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="Titulo de Eleitor">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'titulo-de-eleitor')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="Comprovante de Escolaridade">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'comprovante-de-escolaridade')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
    </div>
    <div class="flex flex-row justify-between gap-4 w-full">
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="Currículo">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'curriculo')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="Comprovante de endereço (contendo CEP)">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'comprovante-de-endereco')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
    </div>
    <div class="flex flex-row justify-between gap-4 w-full">
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="Certidão de Estado civil (caso solteiro, não é necessário)">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'certidao-de-estado-civil')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="Documento de RG e CPF do cônjuge (caso solteiro, não é necessário)">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'documentos-de-rg-conjuge')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
    </div>
    <div class="flex flex-row justify-between gap-4 w-full">
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="Certidão de nascimento de filhos menores de 14 anos (se não tiver filho, não é necessário)">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'certidao-nascimento-filhos')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="CPF de filhos menores de 14 anos (se não tiver filhos, não é necessário)">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'cpf-filhos')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="Cartão de vacina de filhos menores de 6 anos e/ou comprovante de escolaridade para filhos maiores de 6 anos (se não tiver filhos, não é necessário)">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'cartao-vacina-filhos')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
              class="w-1/2"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
    </div>
    </a-form>
    <div class="w-full flex justify-end">
      <a-button key="submit" type="primary" @click="handleSubmit">Enviar</a-button>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, onMounted } from 'vue';
import axiosService from '@/services/axiosService';
import { message } from 'ant-design-vue';
import router from '@/router';

const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
const nome_completo = ref('')
const filesArr = ref([])
const emit = defineEmits(['handleSender'])

const beforeUpload = (file, label) => {
  if (allowedTypes.includes(file.type)) {
    const fileExtension = file.name.split('.').pop();
    const newFileName = `${label}.${fileExtension}`;
    const renamedFile = new File([file], newFileName, { type: file.type });

    filesArr.value.push(renamedFile);
    return false;
  }

  return alert('Formato de arquivo não suportado');
}

const handleSubmit = async () => {
  const formData = new FormData();
  formData.append('nome_completo', nome_completo.value);

  filesArr.value.forEach((file) => {
    formData.append('files[]', file);
  });

  try {
    await axiosService.post('/mail/docs', formData, {
      headers: {
        'content-length': 1000000000000
      }
    })
    emit('handleSender');
  } catch (error) {
    message.warning('Algo deu errado, contate o suporte');
    emit('handleSender');
  }
}

const validateHash = () => {
  const hash = sessionStorage.getItem('roleHash');
  return !hash ? router.push('/error') : true;
}

onMounted(validateHash)

</script>

<style lang="scss" scoped>

</style>