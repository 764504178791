<template>
  <div
    class="fixed inset-0 bg-gray-800 bg-opacity-65 flex items-center justify-center z-50"  
    @click.prevent="handleClose"
  >
    <form @submit.prevent="handleAdd" class="bg-white p-6 rounded-xl shadow-lg background flex justify-between flex-col gap-4" @click.stop>
      <p class="font-semibold font-poppins text-start text-xl">Adicionar um novo cargo</p>
      <input
        type="text" 
        class="bg-white outline-none rounded-xl border-4 text-xl px-4"
        v-model="role"
        @keydown.enter.prevent="handleAdd"
      >
      <div class="flex gap-4 self-end p-4">
        <button class="bg-[#FF8B3D] rounded-xl w-28 h-12 text-black" @click.prevent="handleClose">Cancelar</button>
        <button 
          class="bg-[#0F295E] rounded-xl w-28 h-12 text-white"
          type="submit"
        >Adicionar</button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { defineEmits, ref } from 'vue';
import { message } from 'ant-design-vue';
import axiosService from '@/services/axiosService';


const role = ref('')

const emit = defineEmits(['handleAddModalView', 'reloadList'])

const handleClose = () => {
  emit('handleAddModalView')
}

const handleAdd = async () => {
  const formData = new FormData();
  formData.append('role_name', role.value)
  try {
    await axiosService.post('/roles/create', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
    role.value = ''
    emit('reloadList')
    emit('handleAddModalView')
    message.success('Cargo adicionado')
  } catch (error) {
    message.warning('Algo deu errado, contate o suporte')
  }
}

</script>

<style lang="scss" scoped>

.background {
  background-image: url('../../../assets/baseModal.svg');
  background-size: cover;
  height: 30vh;
  width: 50vw;
}

</style>