<template>
  <div class="flex justify-center items-center min-h-screen">
    <div class="w-16 h-16 border-4 border-t-transparent border-blue-500 border-solid rounded-full animate-spin"></div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>