<template>
  <div class="bg-white bg-opacity-70 z-10 rounded-xl mx-auto my-auto flex flex-col gap-3 py-2 px-1">
    <div class="flex justify-between mx-auto rounded-xl bg-[#0F295E] w-full ">
      <div class="flex justify-start items-center pl-5 gap-3 h-20">
        <div class="rounded-full bg-white size-12 flex justify-center items-center">
          <img :src="prancheta" alt="prancheta" class="h-8">
        </div>
        <h1 class="font-poppins text-white font-semibold text-xl"> Todos os Cargos </h1>
      </div>
      <div class="flex pr-5 gap-3 justify-end items-center py-1 w-1/2">
        <div class="flex items-center rounded-xl w-3/6 bg-white gap-3 p-1">
          <img :src="lupa" alt="lupa" class="h-9 pl-3">
          <input
            class="outline-none bg-none w-2/3"
            type="text"
            v-model="searchInput"
            @change="filterSearch"  
          >
        </div>
        <div
          class="bg-white size-11 rounded-xl flex items-center justify-center"
          @click.prevent="handleAddModalView"
        >
          <img :src="add" alt="add" class="h-5">
        </div>
      </div>
    </div>
    <div class="mx-auto rounded-xl w-full flex gap-4 items-start flex-col overflow-auto">
      <div 
        v-for="(role, index) in filteredList"
        v-bind:key="index"
        class="bg-white w-full h-20 rounded-xl flex items-center gap-5 px-5 font-poppins font-semibold"
      >
        <div class="w-[3%] text-left overflow-hidden"> {{ index + 1 }} </div>
        <div class="flex-grow text-left overflow-hidden"> {{ role.role_name }} </div>
        <div class="w-1/6 flex justify-end py-4 gap-4 items-center">
          <img class="h-6 cursor-pointer" :src="trash" alt="trash" @click.prevent="handleDelete(role.id)">
          <button 
            @click.prevent="handleMessageModalView(role.id)"
            class="bg-[#0F295E] text-white py-1 px-4 rounded-xl cursor-pointer"
          >
            Criar Link
          </button>
        </div>
      </div>
    </div>
  <AddModal 
    v-if="showAddModal"
    @handleAddModalView="handleAddModalView"
    @reloadList="reloadList"
  ></AddModal>
  <MessageModal 
    v-if="showMessageModal"
    :idOpenned="idRoleOpenned"
    @handleMessageModalView="handleMessageModalView"
  ></MessageModal>
  </div>
</template>

<script setup>
import prancheta from '@/assets/Prancheta.svg';
import lupa from '@/assets/Lupa.svg';
import add from '@/assets/Adicionar.svg';
import trash from '@/assets/delete.svg';
import AddModal from '@/components/adm/modals/AddModal.vue';
import MessageModal from '@/components/adm/modals/MessageModal.vue';
import { computed, onBeforeMount, ref } from 'vue';
import { message } from 'ant-design-vue';
import axiosService from '@/services/axiosService';

const showAddModal = ref(false);
const showMessageModal = ref(false);
const idRoleOpenned = ref();
const searchInput = ref('');
const originalList = ref([]);

const filteredList = computed(() => {
  return !originalList.value ? [] : originalList.value.filter(item =>
    item.role_name.toLowerCase().includes(searchInput.value.toLowerCase())
  );
})

const handleAddModalView = () => {
  return showAddModal.value = !showAddModal.value
}

const handleMessageModalView = (id) => {
  idRoleOpenned.value = id;
  return showMessageModal.value = !showMessageModal.value
}

const handleDelete = async (id) => {
  const formData = new FormData()
  formData.append('id', id)
  try {
    await axiosService.post('/roles/delete', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    message.success('Removido com sucesso')
    reloadList();
  } catch (error) {
    message.warning('Algo deu errado, contate o suporte')
  }
}

const reloadList = () => {
  listRequest();
}

const listRequest = async () => {
  try {
    const response = await axiosService.get('/roles')
    originalList.value = response.data;
  } catch (error) {
    message.warning('Algo deu errado, contate o suporte')
  }
}

onBeforeMount(listRequest)

</script>

<style lang="scss" scoped>

</style>