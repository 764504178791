<template>
  <div
    @wheel="handleScroll"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    id="formSection"
    class="h-dvh relative"
  >
    <H1 class="absolute title flex flex-col text-4xl max-[500px]:text-xl max-[500px]:my-2 max-[500px]:pl-10 font-bold font-poppins items-start pl-40 my-4">Documentos<span class="text-age-orange">obrigatórios</span></H1>
    <div class="flex flex-row justify-evenly w-full p-4 h-full items-center max-[500px]:flex-col max-[500px]:justify-center max-[500px]:gap-16">
      <div class="inset-0 card1 z-10 shadow-2xl transform rounded-2xl relative h-4/6 w-1/5 max-[500px]:w-5/6 max-[500px]:h-1/6 flex flex-col justify-end items-center" id="docsForm">
        <img class="absolute -top-10 h-3/5 max-[500px]:-top-12" :src="person1">
        <H4 class="text-4xl font-poppins max-[500px]:text-xl text-[#FA6400] font-extrabold">Formulário 1</H4>
        <p class="mt-2 max-[500px]:mt-0 text-sm max-[500px]:text-xs">Documentos obrigatórios</p>
        <button @click="showDocsModal" class="bg-gradient-to-r from-[#F9B203] to-[#FA6400] items-center w-4/5 rounded-lg m-4 max-[500px]:m-2 p-1 px-4 text-nowrap text-white mt-16">Preencher Formulário</button>
      </div>
      <div class="inset-0 card2 z-10 shadow-2xl transform rounded-2xl relative h-4/6 w-1/5 max-[500px]:w-5/6 max-[500px]:h-1/6 flex flex-col justify-end items-center" id="infoForm">
        <img class="absolute -top-10 h-3/5 max-[500px]:-top-12" :src="person2">
        <H4 class="text-4xl font-poppins max-[500px]:text-xl text-[#FA6400] font-extrabold">Formulário 2</H4>
        <p class="mt-2 max-[500px]:mt-0 text-sm max-[500px]:text-xs">Dados pessoais</p>
        <button @click="showInfoModal" class="bg-gradient-to-r from-[#F9B203] to-[#FA6400] items-center w-4/5 rounded-lg m-4 max-[500px]:m-2 p-1 px-4 text-nowrap text-white mt-16">Preencher Formulário</button>
      </div>
      <div class="inset-0 card3 z-10 shadow-2xl transform rounded-2xl relative h-4/6 w-1/5 max-[500px]:w-5/6 max-[500px]:h-1/6 flex flex-col justify-end items-center" id="otherForm">
        <img class="absolute -top-10 h-3/5 max-[500px]:-top-12" :src="person3">
        <H4 class="text-4xl font-poppins max-[500px]:text-xl text-[#FA6400] font-extrabold">Formulário 3</H4>
        <p class="mt-2 max-[500px]:mt-0 text-sm max-[500px]:text-xs">Infomações adicionais</p>
        <button class="bg-gradient-to-r from-[#F9B203] to-[#FA6400] items-center w-4/5 rounded-lg m-4 max-[500px]:m-2 p-1 px-4 text-nowrap text-white mt-16">Preencher Formulário</button>
      </div>
    </div>
    <a-modal
      v-model:open="openDocsForm"
      class="w-full max-[500px]:w-11/12"
      :width="modalWidth"
    >
      <template #footer>
      </template>
      <DocumentsForm @handleSender="handleSenderDocs"></DocumentsForm>
    </a-modal>
    <a-modal
      v-model:open="openInfoForm" 
      class="w-full max-[500px]:w-11/12"
      :width="modalWidth"
    >
      <template #footer>
      </template>
      <InfoForm @handleSender="handleSenderInfo"></InfoForm>
    </a-modal>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script setup>
import person1 from '@/assets/pessoa1.svg'
import person2 from '@/assets/pessoa2.svg'
import person3 from '@/assets/pessoa3.svg'
import FooterComponent from '@/components/FooterComponent.vue'
import router from '@/router'
import InfoForm from '../forms/InfoForm.vue'
import DocumentsForm from '../forms/DocumentsForm.vue'
import { ref, onMounted, onUnmounted } from 'vue'

const openInfoForm = ref(false);
const openDocsForm = ref(false);
const modalWidth = ref('50%');

const infoFormRefer = ref({});

const updateModalWidth = () => {
  if (window.innerWidth > 500) {
    return modalWidth.value = '65%';
  } 
  return modalWidth.value = '92%';
}

const showInfoModal = () => {
  openInfoForm.value = true;
};

const showDocsModal = () => {
  openDocsForm.value = true;
};

const handleScroll = (event) => {
  setTimeout(() => {
    if (event.deltaY < 0) {
      router.push('/descricao')
    }
  })
}

const handleSenderInfo = (data) => {
  infoFormRefer.value = data;
  openInfoForm.value = false;
};

const handleSenderDocs = () => {
  openDocsForm.value = false;
};

let startY = 0;

const handleTouchStart = (event) => {
  startY = event.touches[0].clientY;
}

const handleTouchMove = (event) => {
  event.preventDefault();      
  const currentY = event.touches[0].clientY;
  const deltaY = currentY - startY;

  setTimeout(() => {
    if (deltaY > 0) {
      router.push('/descricao')
    }
  })
};


const validateHash = () => {
  const hash = sessionStorage.getItem('roleHash');
  return !hash ? router.push('/error') : true;
}

onMounted(() => {
  validateHash();
  updateModalWidth();
  window.addEventListener('resize', updateModalWidth)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateModalWidth)
})

</script>

<style lang="scss" scoped>
.title {
  opacity: 0;
  animation: titleAnimation 2s ease-out forwards
}

.card1 {
  opacity: 0;
  animation: cardAnimation 1.5s ease-out forwards;
}

.card2 {
  opacity: 0;
  animation: cardAnimation 1.5s ease-out forwards;
  animation-delay: 1.5s;
}

.card3 {
  opacity: 0;
  animation: cardAnimation 1.5s ease-out forwards;
  animation-delay: 3s;
}

@keyframes titleAnimation {
  0% {
    opacity: 0;
    left: -100%;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

@keyframes cardAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  } 
}

</style>